var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "clearfix mb-0"
  }, [_c('span', {
    staticClass: "float-md-left d-block d-md-inline-block mt-25"
  }, [_vm._v(" COPYRIGHT © " + _vm._s(new Date().getFullYear()) + " "), _c('b-link', {
    staticClass: "ml-25",
    attrs: {
      "href": "#",
      "target": "_blank"
    }
  }, [_vm._v("ArboEdu")]), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v(", Todos os Direitos Reservados")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }